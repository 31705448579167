/* animation */
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes shaking {
  0% {
    transform: rotate(deg);
  }
  10% {
    transform: rotate(20deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  30% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50%,
  100% {
    transform: rotate(0deg);
  }
}
/* navbar */
.nav-wrapper {
  position: fixed;
  z-index: 1;
  top: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 80%);
}
.nav-bar {
  width: 50%;
  height: 2.1rem;
  margin: 0 auto;
  padding-top: 7.5px;
  display: flex;
  justify-content: space-evenly;
}
.nav-bar a {
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(240, 255, 255, 90%);
}
.nav-bar a :hover {
  color: orange;
  transition: ease-in-out 0.2s;
}
@media screen and (min-width: 600px) and (max-width: 865px) {
  .nav-bar {
    height: 1.7rem;
  }
  .nav-bar a {
    font-size: 0.6rem;
    font-weight: 400;
  }
}
@media screen and (min-width: 300px) and (max-width: 600px) {
  .nav-bar {
    height: 1.4rem;
  }
  .nav-bar a {
    font-size: 0.4rem;
    font-weight: 400;
  }
}

/* Stellar */
.stellar-wrapper {
  background: #000;
}
.stellar-wrapper img {
  width: 100%;
  opacity: 0.75;
}
.stellar-title {
  position: absolute;
  left: 0;
  top: 20%;
  right: 0;
  margin: auto;
}
.stellar-wrapper .stellar-title h1 {
  font-size: 5rem;
  font-weight: 800;
  background-image: linear-gradient(
      135deg,
      hsla(0, 0%, 100%, 0) 56%,
      #ffcaba 98%
    ),
    linear-gradient(25deg, #f5d1ff 23%, #fff 60%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 95%;
}
.stellar-wrapper .stellar-title .p1 {
  font-size: 1rem;
  font-weight: 600;
  color: hsla(0, 0%, 100%, 0.5);
  letter-spacing: 0.08em;
}
.stellar-wrapper .stellar-title .p2 {
  font-size: 1rem;
  font-weight: 400;
  color: hsla(0, 0%, 100%, 0.82);
  transform: translateY(2em);
  letter-spacing: 0.1em;
}
@media screen and (min-width: 750px) and (max-width: 1100px) {
  .stellar-title {
    top: 12%;
  }
  .stellar-wrapper .stellar-title h1 {
    font-size: 3rem;
  }
  .stellar-wrapper .stellar-title .p1 {
    font-size: 0.8rem;
  }
  .stellar-wrapper .stellar-title .p2 {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 485px) and (max-width: 750px) {
  .stellar-title {
    top: 10%;
  }
  .stellar-wrapper .stellar-title h1 {
    font-size: 2rem;
  }
  .stellar-wrapper .stellar-title .p1 {
    font-size: 0.5rem;
  }
  .stellar-wrapper .stellar-title .p2 {
    font-size: 0.5rem;
  }
}
@media screen and (min-width: 410px) and (max-width: 485px) {
  .stellar-title {
    top: 8%;
  }
  .stellar-wrapper .stellar-title h1 {
    font-size: 1.5rem;
  }
  .stellar-wrapper .stellar-title .p1 {
    font-size: 0.3rem;
  }
  .stellar-wrapper .stellar-title .p2 {
    font-size: 0.3rem;
  }
}
@media screen and (max-width: 410px) {
  .stellar-title {
    top: 8%;
  }
  .stellar-wrapper .stellar-title h1 {
    font-size: 1.2rem;
  }
  .stellar-wrapper .stellar-title .p1 {
    font-size: 0.2rem;
  }
  .stellar-wrapper .stellar-title .p2 {
    font-size: 0.2rem;
  }
}

/* address */
.address-wrapper {
  padding-top: 2.5rem;
  width: 100%;
  display: flex;
  flex-basis: 100%;
  justify-content: center;
  background-color: rgb(235, 235, 235);
}
.address-wrapper .address-son {
  margin: 0 1rem 0 1rem;
}
.address-wrapper .address-son .search-bar input {
  border: 0px;
  padding: 1rem 2rem;
  border-radius: 25px;
  width: 40rem;
  height: 3rem;
  background-color: whitesmoke;
}
.search-button {
  background-color: rgb(235, 80, 40);
  border-radius: 25px;
  height: 3rem;
  width: 12rem;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s;
}
.search-button:hover {
  background-color: red;
  cursor: pointer;
  transform: scale(1.1);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 1s;
}
.search-button p {
  color: azure;
  font-weight: 600;
  transform: translateY(0.7rem);
}
@media screen and (min-width: 700px) and (max-width: 980px) {
  .address-wrapper .address-son {
    margin: 0 0.9rem 0 0.9rem;
  }
  .address-wrapper .address-son .search-bar input {
    border-radius: 25px;
    width: 30rem;
    height: 3rem;
  }
  .search-button {
    border-radius: 25px;
    height: 3rem;
    width: 8rem;
  }
  .search-button p {
    font-size: 0.9rem;
    font-weight: 500;
    transform: translateY(0.75rem);
  }
}
@media screen and (min-width: 610px) and (max-width: 700px) {
  .address-wrapper .address-son {
    margin: 0 0.8rem 0 0.8rem;
  }
  .address-wrapper .address-son .search-bar input {
    border-radius: 25px;
    width: 25rem;
    height: 2.8rem;
  }
  .search-button {
    border-radius: 25px;
    height: 2.8rem;
    width: 8rem;
  }
  .search-button p {
    font-size: 0.9rem;
    font-weight: 500;
    transform: translateY(0.75rem);
  }
}
@media screen and (min-width: 440px) and (max-width: 610px) {
  .address-wrapper .address-son {
    margin: 0 0.7rem 0 0.7rem;
  }
  .address-wrapper .address-son .search-bar input {
    border-radius: 20px;
    width: 15rem;
    height: 2rem;
  }
  .search-button {
    border-radius: 20px;
    height: 2rem;
    width: 5rem;
  }
  .search-button p {
    font-size: 0.9rem;
    font-weight: 500;
    transform: translateY(0.3rem);
  }
}
@media screen and (min-width: 300px) and (max-width: 440px) {
  .address-wrapper .address-son {
    margin: 0 0.6rem 0 0.6rem;
  }
  .address-wrapper .address-son .search-bar input {
    border-radius: 20px;
    width: 15rem;
    height: 2rem;
  }
  .search-button {
    border-radius: 20px;
    height: 2rem;
    width: 3rem;
  }
  .search-button p {
    font-size: 0.9rem;
    font-weight: 500;
    transform: translateY(0.3rem);
  }
}

/* cso */
.cso-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  background-color: rgb(235, 235, 235);
  padding: 3em 3em 2em 3em;
}
.cso-left {
  flex-basis: 100%;
  text-align: left;
  margin: 0 4rem 0 5em;
}
.cso-title {
  margin-left: 4rem;
  margin-bottom: 1.5rem;
}
.cso-title h3 {
  font-size: 3rem;
  font-weight: 800;
  color: orange;
}
.cso-title h4 {
  font-size: 2.8rem;
  font-weight: 800;
  color: black;
}
.cso-intro {
  margin-left: 4rem;
  width: 80%;
}
.cso-intro p {
  font-weight: 400;
  color: black;
}
.cso-right {
  text-align: left;
  margin: 0 4rem 0 0;
}
.cso-right h5 {
  font-weight: 700;
  color: orange;
}
.cso-right p {
  font-weight: 400;
  color: black;
}
@media screen and (min-width: 1360px) and (max-width: 1410px) {
  .cso-title h3 {
    font-size: 2.8rem;
  }
  .cso-title h4 {
    font-size: 2.6rem;
  }
}
@media screen and (min-width: 1290px) and (max-width: 1360px) {
  .cso-title h3 {
    font-size: 2.4rem;
    font-weight: 700;
  }
  .cso-title h4 {
    font-size: 2.2rem;
    font-weight: 700;
  }
}
@media screen and (min-width: 1000px) and (max-width: 1290px) {
  .cso-title h3 {
    font-size: 2rem;
    font-weight: 700;
  }
  .cso-title h4 {
    font-size: 1.8rem;
    font-weight: 700;
  }
  .cso-intro p {
    font-size: 0.8rem;
  }
  .cso-right p {
    font-size: 0.8rem;
  }
}
@media screen and (min-width: 800px) and (max-width: 1000px) {
  .cso-title h3 {
    font-size: 1.6rem;
    font-weight: 700;
    transform: translateX(-2rem);
  }
  .cso-title h4 {
    font-size: 1.4rem;
    font-weight: 700;
    transform: translateX(-2rem);
  }
  .cso-intro {
    margin-left: 2rem;
  }
  .cso-intro p {
    font-size: 0.7rem;
  }
  .cso-right p {
    font-size: 0.7rem;
  }
  .cso-right h5 {
    font-size: 1rem;
  }
}
@media screen and (min-width: 510px) and (max-width: 800px) {
  .cso-wrapper {
    display: block;
    margin: 0 auto;
    width: 100%;
    background-color: rgb(235, 235, 235);
    padding: 2em 1em 1em 1em;
  }
  .cso-title {
    width: 100%;
    margin-left: 0rem;
  }
  .cso-title h3 {
    font-size: 1.6rem;
    font-weight: 700;
    transform: translateX(-2rem);
  }
  .cso-title h4 {
    font-size: 1.4rem;
    font-weight: 700;
    transform: translateX(-2rem);
  }
  .cso-intro {
    width: 100%;
    margin: 0 -2rem;
  }
  .cso-intro p {
    font-size: 0.7rem;
  }
  .cso-right {
    margin: 0 2.8rem;
  }
  .cso-right p {
    font-size: 0.7rem;
  }
  .cso-right h5 {
    font-size: 1rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 510px) {
  .cso-wrapper {
    display: block;
    margin: 0 auto;
    width: 100%;
    background-color: rgb(235, 235, 235);
    padding: 3em 1em 2em 1em;
  }
  .cso-left {
    margin: 0 0;
  }
  .cso-title {
    width: 100%;
    margin-left: 0rem;
  }
  .cso-title h3 {
    font-size: 1.4rem;
    font-weight: 700;
    transform: translateX(2.5rem);
  }
  .cso-title h4 {
    font-size: 1.2rem;
    font-weight: 700;
    transform: translateX(2.5rem);
  }
  .cso-intro {
    width: auto;
    margin: 0 2.5rem 0 2.8rem;
  }
  .cso-intro p {
    font-size: 0.7rem;
  }
  .cso-right {
    margin: 0 2.8rem;
  }
  .cso-right p {
    font-size: 0.7rem;
  }
  .cso-right h5 {
    font-size: 1rem;
  }
}

/* card */
.cards-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(0, 0, 0, 90%);
  border-radius: 0;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.card-item {
  width: 10rem;
}
.card-item p {
  color: white;
  font-size: 1.5rem;
  font-style: italic;
}
.card-item p span {
  color: rgb(237, 125, 49);
}
@media screen and (min-width: 730px) and (max-width: 1000px) {
  .card-item p {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 730px) {
  .card-item {
    margin: 0 0.5rem 0 0.5rem;
  }
  .card-item p {
    font-size: 0.7rem;
  }
}

/* about */
.about-wrapper {
  display: flex;
  margin: 0 auto;
  background: orange;
  padding: 2rem 0 3rem 0;
}
.about-card {
  width: 70%;
  background-color: whitesmoke;
  padding: 5rem 3rem 3rem 5rem;
  border-radius: 100px;
  display: flex;
  align-items: center;
  margin-left: 5rem;
  box-shadow: 10px 15px rgba(0, 0, 0, 20%);
}
.about-card-left {
  text-align: left;
  margin-right: 3rem;
}
.about-card-left .about-title {
  text-shadow: 2px 2px rgba(0, 0, 0, 50%);
  color: rgb(237, 125, 49);
  font-weight: bolder;
}
.about-card-left h3 {
  color: black;
}
.about-card-right {
  text-align: left;
  color: black;
  margin-right: 5rem;
}
.about-card-right p {
  font-size: 1.1rem;
  font-weight: 400;
}
.about-avatar img {
  width: 250px;
  transform: translateX(-3rem) translateY(8rem) rotateZ(10deg);
  transition: ease-in-out 0.5s;
}
.about-avatar img:hover {
  cursor: pointer;
  transform: translateX(-3rem) translateY(8rem) rotateZ(30deg);
  transition: ease-in-out 0.5s;
}
@media screen and (min-width: 300px) and (max-width: 1150px) {
  .about-wrapper {
    display: block;
  }
  .about-card {
    display: block;
    padding: 1rem 1rem;
    width: 85%;
    border-radius: 50px;
    margin: 1rem auto 0 auto;
  }
  .about-card-left {
    margin: 1rem 0 1rem 1rem;
  }
  .about-card-left h1 {
    font-size: 1.2rem;
  }
  .about-card-left h3 {
    font-size: 1.1rem;
  }
  .about-card-right {
    margin: 0 1rem 0 1rem;
  }
  .about-card-right p {
    font-size: 0.8rem;
  }
  .about-avatar {
    display: none;
  }
}
/* tokenomics */
.tokenomics-wrapper {
  padding-top: 1rem;
  background-color: rgb(234, 234, 234);
  padding-bottom: 5rem;
}
.huge-shadow {
  margin: 0 auto;
  color: rgba(0, 0, 0, 5%);
  font-size: 8rem;
  font-weight: 600;
}
.tokenomics-title {
  transform: translateY(-4.5rem);
}
.tokenomics-title h1 {
  font-weight: bold;
  font-size: 2rem;
}
.tokenomics-title span {
  font-weight: bold;
  font-size: 2rem;
  color: rgb(237, 125, 49);
}
.tokenomics-sub-wrapper {
  display: flex;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.tokenomics-card {
  background-color: whitesmoke;
  border-radius: 50px;
  margin: 1rem auto;
  width: 25rem;
  height: 15rem;
}
.tokenomics-card-title {
  margin-top: 0.65rem;
}
.tokenomics-card-title p {
  color: orangered;
  font-size: 4rem;
  font-weight: 700;
}
.tokenomics-card-content {
  width: 13rem;
  margin: 0 auto;
}
.tokenomics-card-content p {
  font-size: 1.3rem;
  font-weight: 600;
  text-shadow: 0.25px 1px rgb(237, 125, 49);
  font-style: italic;
}
.tokenomics-avatar {
  transform: translateY(4rem);
}
.tokenomics-avatar img {
  animation: shaking 2s ease-in-out infinite;
  width: 12rem;
}
.tokenomics-avatar img:hover {
  cursor: pointer;
}
.tokenomics-bar img {
  width: 95%;
}
.tokenomics-bar-avatar {
  position: absolute;
  transform: translateX(10rem) translateY(2rem);
  transition: ease-in-out 0.8s;
}
.tokenomics-bar-avatar:hover {
  transform: translateX(10rem) translateY(2rem) scale(1.05);
  transition: ease-in-out 1s;
}
.tokenomics-bar-avatar img {
  width: 10rem;
  animation: rotation 15s linear infinite;
}
.tokenomics-bar-avatar img:hover {
  cursor: pointer;
}
@media screen and (min-width: 300px) and (max-width: 1100px) {
  .huge-shadow {
    font-size: 4rem;
  }
  .tokenomics-title {
    transform: translateY(-2.8rem);
  }
  .tokenomics-title h1 {
    font-size: 1.6rem;
  }
  .tokenomics-title h1 span {
    font-size: 1.6rem;
  }
  .tokenomics-avatar {
    display: none;
  }
  .tokenomics-bar-avatar {
    display: none;
  }
}
@media screen and (min-width: 765px) and (max-width: 900px) {
  .tokenomics-sub-wrapper {
    display: block;
  }
  .tokenomics-card {
    padding-top: 0.1rem;
  }
  .left {
    margin-left: 7rem;
    margin-bottom: 3rem;
  }
  .right {
    margin-right: 7rem;
  }
}
@media screen and (min-width: 550px) and (max-width: 765px) {
  .tokenomics-sub-wrapper {
    display: block;
  }
  .left {
    margin-bottom: 3rem;
  }
}
@media screen and (min-width: 400px) and (max-width: 550px) {
  .tokenomics-sub-wrapper {
    display: block;
    margin-top: -2rem;
  }
  .left {
    margin-bottom: 3rem;
  }
  .tokenomics-sub-wrapper {
    transform: scale(0.85);
    margin-bottom: -1rem;
  }
  .tokenomics-wrapper {
    margin-bottom: -3rem;
  }
}
@media screen and (min-width: 300px) and (max-width: 400px) {
  .tokenomics-sub-wrapper {
    display: block;
    margin-top: -4rem;
    margin-bottom: -2rem;
  }
  .left {
    margin-bottom: 3rem;
  }
  .tokenomics-sub-wrapper {
    transform: scale(0.75);
  }
  .tokenomics-wrapper {
    margin-bottom: -3rem;
  }
}

/* roadmap */
.roadmap-wrapper {
  padding-top: 3rem;
  padding-bottom: 5rem;
  background-color: orange;
  height: 33rem;
}
.roadmap-title {
  height: 30px;
  margin-bottom: 5rem;
}
.roadmap-title h1 {
  font-size: 3rem;
  font-weight: bold;
  font-style: italic;
  color: white;
  margin-bottom: 4rem;
  text-shadow: 8px 1px red;
}
.roadmap-title h2 {
  font-size: 8rem;
  font-weight: 600;
  transform: translateY(10rem) scale(1.1, 1);
  color: rgba(0, 0, 0, 5%);
}
.roadmap-top-wrapper {
  width: 60%;
  margin: 2rem auto 2rem auto;
}
.roadmap-top {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.roadmap-top .roadmap-button {
  /* border: solid 1.8px #fff; */
  padding: 0.5rem 2rem 0 2rem;
  border-radius: 20px;
  background-color: rgba(255, 68, 0, 100%);
  opacity: 90%;
  transition: ease-in-out 0.3s;
  height: 2.7rem;
  width: 9rem;
  margin: 0 0.5rem 0 0.5rem;
}
.roadmap-top .roadmap-button:hover {
  cursor: pointer;
  opacity: 100%;
  background-color: red;
  transform: scale(1.1);
  transition: ease-in-out 0.3s;
}
.roadmap-top .roadmap-button p {
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
}
.roadmap-bottom {
  margin: 4rem auto 0 auto;
  width: 80%;
  text-align: center;
}
.roadmap-bottom p {
  font-size: 1.2rem;
  font-weight: 500;
  font-style: italic;
  color: white;
  text-shadow: 1px 3px rgba(255, 68, 0, 0.2);
  line-height: 75%;
}
.roadmap-avatar-wrapper {
  position: absolute;
  transform: translateX(70rem) translateY(10rem);
  clear: both;
}
.roadmap-avatar-wrapper img {
  width: 12rem;
  transition: ease-in-out 0.3s;
}
.roadmap-avatar-wrapper img:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: ease-in-out 0.5s;
}

/* footage */
.footage {
  background-color: rgba(0, 0, 0, 85%);
  cursor: pointer;
}
.footage p {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 400;
  margin: 0 0;
  padding: 0.2rem 0 0.2rem 0;
  text-decoration: underline;
}
